// extracted by mini-css-extract-plugin
export var ButtonWrapper = "Artistes-module--ButtonWrapper --EKQV-";
export var ColumnWrapper = "Artistes-module--ColumnWrapper--vdNYv";
export var FalseLinkWrapper = "Artistes-module--FalseLinkWrapper--aNg37";
export var LinkWrapper = "Artistes-module--LinkWrapper--m-8cf";
export var ListHeader = "Artistes-module--ListHeader--Z-H0P";
export var ListWrapper = "Artistes-module--ListWrapper--MXRAy";
export var MenuButton = "Artistes-module--MenuButton--t53aa";
export var NavButton = "Artistes-module--NavButton--DxK2W";
export var SubListWrapper = "Artistes-module--SubListWrapper--AEf8x";
export var Wrapper = "Artistes-module--Wrapper--u8LcY";